


import { Squircle } from "@squircle-js/react"
import { useState } from "react";
import TitanNavigator from "../../TitanNavigator";
import Window from './window'

import './titan.css'

export default function TitanOS() {

  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const emptyDockApp = {
    iconName: 'Blank',
    iconType: 'blank',
    iconBackground: 'white',
    iconStatus: 'close'
  }

  const [dockApps, setDockApps] = useState<any>([
    {
      iconName: 'Titan',
      iconType: 'svg',
      iconSvg: (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.7736 0.692383C11.8021 9.87884 8.00776 16.4977 0.0195312 21.0625L0.247766 21.4618C8.20745 16.8115 15.7962 16.8115 23.7559 21.4618L23.9841 21.0625C15.996 16.4977 12.2015 9.87884 12.2301 0.692383H11.7736Z" fill="black"/>
        </svg>
      ),
      iconBackground: 'white',
      iconStatus: 'open'
    },
    {
      iconName: 'Reyes',
      iconType: 'svg',
      iconSvg: (
        <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.748156 19.8057L4.85942 12.4338L2.06536 14.3863L0.748156 19.8057Z" fill="white"/>
          <path d="M6.25645 14.9442L5.61781 19.8057L7.85306 29.409L5.21866 24.3085L3.38256 19.8854L6.25645 14.9442Z" fill="white"/>
          <path d="M0.46875 12.9916L3.82163 9.56469L6.33628 9.92332L0.46875 12.9916Z" fill="white"/>
          <path d="M5.41823 8.44894L4.5401 7.1738L4.85942 6.37684H5.8573L7.25433 7.85122L7.57365 9.60454L8.89086 7.01441L6.33628 4.50398L4.22078 4.86261L2.98341 6.89487L3.58214 8.44894H5.41823Z" fill="white"/>
          <path d="M14 1.99355L12.4433 0.240234L12.0442 2.79051L8.2123 1.11689L8.77111 2.98975L5.8573 2.23264L10.0484 4.78292L9.48959 2.55142L12.603 4.78292L12.8824 2.0334L14 3.0296L15.1176 2.0334L15.397 4.78292L18.5104 2.55142L17.9516 4.78292L22.1427 2.23264L19.2289 2.98975L19.7877 1.11689L15.9558 2.79051L15.5567 0.240234L14 1.99355Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 12.2345L13.4013 10.4413L12.4034 9.56469L13.4811 8.60834L11.8047 7.09411L10.1282 7.29335L9.40975 8.28955L10.0484 8.96697L7.97281 12.0751L8.2123 14.9442L9.32992 16.0998L10.3278 15.2231L9.56941 17.7734L11.2858 20.0049L13.521 18.4508V17.2554L11.7648 15.9005L12.0841 15.263L14 15.6615L15.9159 15.263L16.2353 15.9005L14.479 17.2554V18.4508L16.7142 20.0049L18.4306 17.7734L17.6722 15.2231L18.6701 16.0998L19.7877 14.9442L20.0272 12.0751L17.9516 8.96697L18.5902 8.28955L17.8718 7.29335L16.1953 7.09411L14.5189 8.60834L15.5966 9.56469L14.5987 10.4413L14 12.2345ZM11.4454 11.7165L9.17026 11.3977L9.84882 12.5134L12.124 13.7886L11.4454 11.7165ZM16.5546 11.7165L18.8297 11.3977L18.1512 12.5134L15.876 13.7886L16.5546 11.7165Z" fill="white"/>
          <path d="M13.3614 19.6064L11.7248 21.1206L10.9265 21.0409V21.7582L12.0442 22.874H15.9558L17.0735 21.7582V21.0409L16.2752 21.1206L14.6386 19.6064H13.3614Z" fill="white"/>
          <path d="M13.8803 31.7601L9.17026 23.4717L8.33204 26.3407L13.6807 31.7601H13.8803Z" fill="white"/>
          <path d="M14.1197 31.7601L18.8297 23.4717L19.668 26.3407L14.3193 31.7601H14.1197Z" fill="white"/>
          <path d="M21.7435 14.9442L22.3822 19.8057L20.1469 29.409L22.7813 24.3085L24.6174 19.8854L21.7435 14.9442Z" fill="white"/>
          <path d="M27.5312 12.9916L24.1784 9.56469L21.6637 9.92332L27.5312 12.9916Z" fill="white"/>
          <path d="M22.5818 8.44894L23.4599 7.1738L23.1406 6.37684H22.1427L20.7457 7.85122L20.4263 9.60454L19.1091 7.01441L21.6637 4.50398L23.7792 4.86261L25.0166 6.89487L24.4179 8.44894H22.5818Z" fill="white"/>
          <path d="M27.2518 19.8057L23.1406 12.4338L25.9346 14.3863L27.2518 19.8057Z" fill="white"/>
        </svg>
      ),
      iconBackground: 'black',
      iconStatus: 'open'
    },
    emptyDockApp,
    emptyDockApp,
    emptyDockApp,
    emptyDockApp,
  ])

  

  return <div className='titan-screen'>
    <TitanNavigator />
    <Window />
    <div className="dock" onMouseLeave={handleMouseLeave}>
      {
        dockApps.map((i:any, index:number) => <div
          key={index}
          className='icon'
          onMouseEnter={() => handleMouseEnter(index)}>
            <Squircle
              cornerRadius={(hoverIndex !== null ? 9.25 * (1 + 1 * Math.max(0, 0.5 - Math.abs(hoverIndex - index) * 0.25)) : 9.25)}
              cornerSmoothing={1}
              className={`squirtle ${hoverIndex !== null ? 'transition' : ''}`}
              width={(hoverIndex !== null ? 40 * (1 + 1.25 * Math.max(0, 0.5 - Math.abs(hoverIndex - index) * 0.25)) : 40)}
              height={(hoverIndex !== null ? 40 * (1 + 1.25 * Math.max(0, 0.5 - Math.abs(hoverIndex - index) * 0.25)) : 40)}
              style={{
                background: i.iconBackground,
                display: 'flex',
              }}
            >
              {
                i.iconType == 'svg' ? i.iconSvg : <></>
              }
            </Squircle>
            <div className="feature-open" style={{opacity: (i.iconStatus == 'open' ? 1 : 0), transition: '150ms'}}></div>
          </div>
        )
      }
    </div>
  </div>
}