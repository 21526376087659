import { useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';

export default function UVInput(
  mode='text',
  placeholder='',
  required=false,
  isarea=false,
  title=undefined,
  hint=undefined,
  setHandling=(_:any)=>{},
  linking:any={
    enabled: false,
    titling: undefined,
    sourcing: undefined
  },
  onHandle=(_:any)=>{},
  nonText:any=undefined
) {

  const [visibility, setVisibility] = useState<any>(false)
  const [text, setText] = useState<any>('')

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && prompt.length > 0) {
      // alert(`You pressed Enter with input value: ${prompt}`);
      // make_inference()
      // event.preventDefault()
    }
  };

  return <div className="cg-input">
    {
      title !== undefined ? <h5><span>{title}</span></h5> : <></>
    }
    
    <div className="base" style={{height: (isarea ? 'fit-content' : 32)}}>
      {
        mode === 'search' ? <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.96025 8.66475C8.74058 8.44508 8.38442 8.44508 8.16475 8.66475C7.94508 8.88442 7.94508 9.24058 8.16475 9.46025L8.96025 8.66475ZM11.1648 12.4602C11.3844 12.6799 11.7406 12.6799 11.9602 12.4602C12.1799 12.2406 12.1799 11.8844 11.9602 11.6648L11.1648 12.4602ZM9.125 6.0625C9.125 8.03001 7.53001 9.625 5.5625 9.625V10.75C8.15133 10.75 10.25 8.65133 10.25 6.0625H9.125ZM5.5625 9.625C3.59499 9.625 2 8.03001 2 6.0625H0.875C0.875 8.65133 2.97367 10.75 5.5625 10.75V9.625ZM2 6.0625C2 4.09499 3.59499 2.5 5.5625 2.5V1.375C2.97367 1.375 0.875 3.47367 0.875 6.0625H2ZM5.5625 2.5C7.53001 2.5 9.125 4.09499 9.125 6.0625H10.25C10.25 3.47367 8.15133 1.375 5.5625 1.375V2.5ZM8.16475 9.46025L11.1648 12.4602L11.9602 11.6648L8.96025 8.66475L8.16475 9.46025Z" fill="white" fill-opacity="0.5"/>
        </svg> : <></>
      }
      {
        isarea ? <TextareaAutosize value={text} onChange={(e:any) => {
          setText(e.target.value)
          setHandling(e.target.value)
        }} onKeyDown={(e:any) => handleKeyPress(e)} maxRows={8} placeholder={placeholder + (required ? ' * ' : '')}/> :
        <input type={mode === 'password' ? (visibility ? 'text' : 'password') : 'text'} placeholder={placeholder + (required ? ' * ' : '')} value={nonText !== undefined ? nonText : text} onChange={(e:any) => {
          setText(e.target.value)
          setHandling(e.target.value)
          onHandle(e.target.value)
        }} onKeyDown={(e:any) => handleKeyPress(e)}></input>
      }
      {
        ((mode === 'password') && (!isarea)) ? <h6 onClick={() => {setVisibility(!visibility)}} >{ visibility ? 'HIDE' : 'SHOW' }</h6> : <></>
      }
    </div>
    {
      hint !== undefined ? <h5>{hint}</h5> : <></>
    }
    {
      linking !== undefined ? <h5 className='rightf' onClick={() => {window.location.href = linking.sourcing}}>{linking.titling}</h5> : <></>
    }
    
  </div>
}