
import React, { useEffect, useState } from 'react';



function MenuProp(text:any, type:any, handle:any) {

  return <div className='menu-prop'>
    <div className="menu-standard-prop">

    </div>
    <div className="menu-submenu"></div>
  </div>

  return {
    text: text,
    type: type,
    handle: handle
  }
}

export default function TitanNavigator() {

  const [currentMenu, setCurrentMenu] = useState<any>(null)

  function getCurrentTime(): string {
    const date = new Date();
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const day = date.getDate();
    const hour = date.getHours() % 12 || 12; // 12-hour clock
    const minute = date.getMinutes();
    const amPm = date.getHours() < 12 ? 'AM' : 'PM';
    return `${dayOfWeek} ${month} ${day} ${hour}:${minute.toString().padStart(2, '0')} ${amPm}`;
  }

  return (
    <div className="titan-navigator">
      <div className="row">
        {
          [
            {
              type: 'svg',
              value: (<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.62648 0.473633C6.64113 5.19159 4.69242 8.59089 0.589844 10.9353L0.70706 11.1404C4.79498 8.75207 8.6924 8.75207 12.7803 11.1404L12.8975 10.9353C8.795 8.59089 6.84625 5.19159 6.8609 0.473633H6.62648Z" fill="white"/>
              </svg>),
            },
            {
              type: 'text-highlight',
              value: 'Admin'
            },
            {
              type: 'text',
              value: 'File'
            },
            {
              type: 'text',
              value: 'Explore'
            },
            {
              type: 'text',
              value: 'Tools'
            },
            {
              type: 'text',
              value: 'OS'
            },
          ].map((i:any, n:any) => <div className='prop' 
            style= {{
              backgroundColor: (currentMenu == n ? 'rgba(255, 255, 255, 0.2)' : 'transparent'),
              transition: '150ms'
            }}
            onMouseEnter={() => setCurrentMenu(n)} onMouseLeave={() => {setCurrentMenu(null)}}>
            {
              i.type == 'svg' ? i.value : (
                i.type == 'text' ? <h3>{i.value}</h3> : (
                  i.type == 'text-highlight' ? <h3><span>{i.value}</span></h3> : (
                    <></>
                  )
                )
              )
            }
          </div>)
        }
      </div>
      <div className="row">
        <div className='prop'>
          <h3>{getCurrentTime()}</h3>
        </div>
      </div>
    </div>
  );
}
