// src/hooks/useRealtime.ts
import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js'

const SUPABASE_URL = 'https://meovioilqbwabzmndrxb.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1lb3Zpb2lscWJ3YWJ6bW5kcnhiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODk3NTM0OTQsImV4cCI6MjAwNTMyOTQ5NH0.iNM0xlTc8B2Png2ROmw4XKswZuw4d-VwQQ4XpQpTdgw';
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

export const useRealtime = (_channel_name: string) => {

  const DEFAULT_CHANNEL_NAME = 'realtime'
  const DEFAULT_TYPE = 'broadcast'
  const DEFAULT_EVENT = 'ryse-hydrate'

  function _subscribe(channel_name:string='realtime', type:string='broadcast', event:string='ryse-hydrate') {
    // const channel = supabase.channel(channel_name)
    // channel.on("broadcast", { event: "*" }, (payload) => {
    //   console.log("Cursor position received!", payload);
    // })
    supabase
    .channel(channel_name)
    .on('postgres_changes', { event: '*', schema: '*' }, (payload:any) => {
      console.log('Change received!', payload)
    })
    .subscribe()
    // .subscribe((status) => {
      // if (status === "SUBSCRIBED") {
      //   channel.send({
      //     type: type,
      //     event: event,
      //     payload: { x: Math.random(), y: Math.random() },
      //   });
      // }
    // });
  }

  function _stream() {

  }

  useEffect(() => {
    _subscribe()
  }, []);

  useEffect(() => {
    supabase
    .channel('realtime')
    .send({
      type: 'broadcast',
      event: 'cursor-pos',
      payload: { x: Math.random(), y: Math.random()
      },
    })

  }, []) 


  // return data;
};
