import { useRef, useState } from "react"


export default function Window() {
  const initialWidth = 1000;
  const initialHeight = 500;
  const minWidth = 500;
  const minHeight = 250;
  const [positionX, setPositionX] = useState<number>(50)
  const [positionY, setPositionY] = useState<number>(50)
  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [dragging, setDragging] = useState(false)
  const [size, setSize] = useState({ width: initialWidth, height: initialHeight });
  const [mode, setMode] = useState<any>('normal')

  const windowRef = useRef<HTMLDivElement>(null);
  const draggingRef = useRef<{ isDragging: boolean; startX: number; startY: number }>({
    isDragging: false,
    startX: 0,
    startY: 0,
  });
  const resizingRef = useRef<any>({
    isResizing: false,
    startWidth: 0,
    startHeight: 0,
    startX: 0,
    startY: 0,
  });


  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    draggingRef.current.isDragging = true;
    draggingRef.current.startX = e.clientX - position.x;
    draggingRef.current.startY = e.clientY - position.y;

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (draggingRef.current.isDragging) {
      const newX = e.clientX - draggingRef.current.startX;
      const newY = e.clientY - draggingRef.current.startY;
      setPosition({ x: Math.max(newX, 0), y: Math.max(newY, 0) });
    } else if (resizingRef.current.isResizing) {
      const newWidth = Math.max(resizingRef.current.startWidth + (e.clientX - resizingRef.current.startX), minWidth);
      const newHeight = Math.max(resizingRef.current.startHeight + (e.clientY - resizingRef.current.startY), minHeight);
      setSize({ width: newWidth, height: newHeight });
    }
  };

  const handleMouseUp = () => {
    draggingRef.current.isDragging = false;
    resizingRef.current.isResizing = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  // Resizing logic
  const handleResizeMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent dragging while resizing
    resizingRef.current.startWidth = size.width;
    resizingRef.current.startHeight = size.height;
    resizingRef.current.startX = e.clientX;
    resizingRef.current.startY = e.clientY;
    const rect = resizingRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left; // X position relative to the div
    const y = e.clientY - rect.top;
    const boundary = 5;
    if (((size.width - x) < boundary) || ((size.height - y) < boundary)) {
      resizingRef.current.isResizing = true;
    }
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return <div className='titan-window' ref={resizingRef} onMouseDown={handleResizeMouseDown} style={{top: position.y, left: position.x, width: (mode == 'fill' ? '100%' : size.width), height: (mode == 'fill' ? '100%' : size.height), transition: (mode == 'fill' ? '300ms' : '')}}>
    <div  className={`titan-window-drag ${dragging ? 'titan-window-drag-enabled' : ''}`} onMouseDown={handleMouseDown}>
      <div className="titan-window-controls">
        <div className="titan-window-controls-dot titan-window-controls-dot-close" onClick={() => setMode('off')}></div>
        <div className="titan-window-controls-dot titan-window-controls-dot-minimize" onClick={() => setMode('min')}></div>
        <div className="titan-window-controls-dot titan-window-controls-dot-zoom" onClick={() => {setMode('fill'); setPosition({x:0,y:0})}}></div>
      </div>
    </div>
    <div className="titan-window-content" >
      
    </div>
  </div>
}