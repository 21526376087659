
import axios from "axios";
import { useEffect, useState } from "react";

export function stf(content:any) {
  if ((content !== undefined) && (content !== null)) {
    return content
  }
  return ''
}

export const languageFullNames: { [key: string]: string } = {
  'det' : 'Detect Language', 'af': 'Afrikaans', 'sq': 'Albanian', 'am': 'Amharic', 'ar': 'Arabic', 'hy': 'Armenian', 'az': 'Azerbaijani', 'eu': 'Basque',
  'be': 'Belarusian', 'bn': 'Bengali', 'bs': 'Bosnian', 'bg': 'Bulgarian', 'ca': 'Catalan', 'ceb': 'Cebuano', 'ny': 'Chichewa',
  'zh-CN': 'Chinese (Simplified)', 'zh-TW': 'Chinese (Traditional)', 'co': 'Corsican', 'hr': 'Croatian', 'cs': 'Czech',
  'da': 'Danish', 'nl': 'Dutch', 'en': 'English', 'eo': 'Esperanto', 'et': 'Estonian', 'tl': 'Filipino', 'fi': 'Finnish',
  'fr': 'French', 'fy': 'Frisian', 'gl': 'Galician', 'ka': 'Georgian', 'de': 'German', 'el': 'Greek', 'gu': 'Gujarati',
  'ht': 'Haitian Creole', 'ha': 'Hausa', 'haw': 'Hawaiian', 'iw': 'Hebrew', 'hi': 'Hindi', 'hmn': 'Hmong', 'hu': 'Hungarian',
  'is': 'Icelandic', 'ig': 'Igbo', 'id': 'Indonesian', 'ga': 'Irish', 'it': 'Italian', 'ja': 'Japanese', 'jw': 'Javanese',
  'kn': 'Kannada', 'kk': 'Kazakh', 'km': 'Khmer', 'rw': 'Kinyarwanda', 'ko': 'Korean', 'ku': 'Kurdish', 'ky': 'Kyrgyz',
  'lo': 'Lao', 'la': 'Latin', 'lv': 'Latvian', 'lt': 'Lithuanian', 'lb': 'Luxembourgish', 'mk': 'Macedonian', 'mg': 'Malagasy',
  'ms': 'Malay', 'ml': 'Malayalam', 'mt': 'Maltese', 'mi': 'Maori', 'mr': 'Marathi', 'mn': 'Mongolian', 'my': 'Burmese',
  'ne': 'Nepali', 'no': 'Norwegian', 'ps': 'Pashto', 'fa': 'Persian', 'pl': 'Polish', 'pt': 'Portuguese', 'pa': 'Punjabi',
  'ro': 'Romanian', 'ru': 'Russian', 'sm': 'Samoan', 'gd': 'Scots Gaelic', 'sr': 'Serbian', 'st': 'Sesotho', 'sn': 'Shona',
  'sd': 'Sindhi', 'si': 'Sinhala', 'sk': 'Slovak', 'sl': 'Slovenian', 'so': 'Somali', 'es': 'Spanish', 'su': 'Sundanese',
  'sw': 'Swahili', 'sv': 'Swedish', 'tg': 'Tajik', 'ta': 'Tamil', 'te': 'Telugu', 'th': 'Thai', 'tr': 'Turkish', 'tk': 'Turkmen',
  'uk': 'Ukrainian', 'ur': 'Urdu', 'ug': 'Uyghur', 'uz': 'Uzbek', 'vi': 'Vietnamese', 'cy': 'Welsh', 'xh': 'Xhosa',
  'yi': 'Yiddish', 'yo': 'Yoruba', 'zu': 'Zulu'
};

export async function LidaNP(content:any, from:any='det', to:any='en') {

  const couples = [
    ['.', '°'],
    ['+', '|'],
    ['\n', '^']
  ]

  function encode_prompt(compute_text:any) {
    couples.forEach((i:any) => {
      compute_text = compute_text.replaceAll(i[0], i[1])
    })
    return compute_text
  }

  function decode_prompt(compute_text:any) {
    couples.forEach((i:any) => {
      compute_text = compute_text.replaceAll(i[1], i[0])
    })
    return compute_text
  }

  if (content !== undefined) {
    if (content.length > 0) {
      if (from !== to) {
        if (Object.keys(languageFullNames).includes(from) && Object.keys(languageFullNames).includes(to)) {
          try {
            return await axios.get(
              `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${from}&tl=${to}&dt=t&q=${encodeURI(
                encode_prompt(content)
              )}`
            ).then((response:any) => decode_prompt(response.data[0][0][0]))
          } catch {
    
          }
        }
      }
    }
  }

  return content
}

var memoization: any = [];

function setMemoization(arr: any): void {
  memoization = arr
}

export function LidaT(content:any, from:any='det', to:any='en', capitalize:boolean=false, flatten:any=0):any {

  const default_to_language = 'en'

  const [text, setText] = useState<any>(content)
  const [display, setDisplay] = useState<any>(text)
  const [lastText, setLastText] = useState<any>(undefined)
  const [lastTo, setLastTo] = useState<any>(undefined)
  const [fromLanguage, setFromLanguage] = useState<any>(from)
  const [toLanguage, setToLanguage] = useState<any>((to !== null ? to : default_to_language).replaceAll('WWW', default_to_language))
  // const [memoization, setMemoization] = useState<any>([])


  const couples = [
    ['.', '°'],
    ['+', '|'],
    ['\n', '^']
  ]

  function findInMemoization(x: any, lf: any, lt: any) {
    const foundItem = memoization.find(
      (i:any) =>
        i.content === x &&
        i.from_language === lf &&
        i.to_language === lt
    );
    return foundItem || null;
  }

  function memoize(x: any, rs: any, lf: any, lt: any) {
    const payload = {
      content: x,
      result: rs,
      from_language: lf,
      to_language: lt,
    };

    let exists = memoization.some(
      (i:any) =>
        i.content === payload.content &&
        i.from_language === payload.from_language &&
        i.to_language === payload.to_language
    );

    if (!exists) {
      memoization.push(payload);
      setMemoization(memoization);
    }
  }

  useEffect(() => {
    setFromLanguage(from)
    setToLanguage(to)
  }, [from, to])


  function encode_prompt(compute_text:any) {
    couples.forEach((i:any) => {
      compute_text = compute_text.replaceAll(i[0], i[1])
    })
    return compute_text
  }

  function capitalizeFirstLetter(str: string): string {
    try {
      if (str.length === 0) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    } catch {
      return str
    }
  }

  function decode_prompt(compute_text:any) {
    couples.forEach((i:any) => {
      compute_text = compute_text.replaceAll(i[1], i[0])
    })
    return compute_text
  }

  function processText(to_process:any, l_from:any, l_to:any) {
    if (to_process.length > 0) {
      if (l_from !== l_to) {
        if (Object.keys(languageFullNames).includes(l_from) && Object.keys(languageFullNames).includes(l_to)) {
          try {
            axios.get(
              `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${l_from}&tl=${l_to}&dt=t&q=${encodeURI(
                encode_prompt(to_process)
              )}`
            ).then((response:any) => {
              setLastTo(l_to)
              setLastText(to_process)
              const dt = decode_prompt(response.data[0][0][0])
              setDisplay(dt)
              memoize(to_process, dt, l_from, l_to)
            })
          } catch {
    
          }
        } else {
          setDisplay(to_process)
        }
      } else {
        setDisplay(to_process)
      }
    }
  }

  useEffect(() => {
    if ((lastText !== content) || (lastTo !== to)) {
      const found = findInMemoization(content, from, to)
      if (found == null) {
        processText(content, from, to) 
      } else {
        setLastTo(to)
        setLastText(content)
        setDisplay(found.result)
      
      }
    }
  }, [content, from, to])

  return <>{capitalize ? capitalizeFirstLetter(flatten == -1 ? display.toLowerCase() : (flatten == 1 ? display.toUpperCase() : display)) : (flatten == -1 ? display.toLowerCase() : (flatten == 1 ? display.toUpperCase() : display))}</>
}
